import { defineStore } from 'pinia'
import PermisosService from '../../../services/permisos.service'
const _PermisosService = new PermisosService()

export const usePermisosStore = defineStore('permisosStore', {
  state: () => ({
    permisos_flitrados: [],
    permisos_paginado: {},
    params: {
      limit: 10,
      offset: 0,
      page: 0,
      global: ''
    }
  }),
  getters: {
    _filtro_global_permisos: state => state.filtro_global_permisos,
    _permisos_flitrados: state => state.permisos_flitrados,
    _permisos_paginado: state => state.permisos_paginado,
    _params: state => state.params
  },
  actions: {
    buscarPermisos (global) {
      _PermisosService.search(global).then(({ data }) => {
        this.permisos_flitrados = data
      })
    },
    paginarPermisos () {
      _PermisosService.paginarPermisos(this.params).then(({ data }) => {
        const listadoPermisos = data
        listadoPermisos.rows.map(a => {
          if (a.parent_permission_id) a.nombre_permiso_padre = a.permisoPadre.name
          if (a.service_id) a.nombre_servicio_padre = a.servicioPadre.name
          if (a.permisosHijos) { a.edicion = a.permisosHijos.some(e => e.creacion_edicion) } else { a.edicion = false }
          return a
        })
        this.permisos_paginado = listadoPermisos
      })
    },
    crearPermiso (payload) {
      return _PermisosService.crear(payload).then(({ data }) => {
        // this.paginarPermisos()
        return data
      })
    },
    encontrarPermiso (id) {
      return _PermisosService.find(id).then(({ data }) => {
        return Promise.resolve(data)
      })
    },
    editarPermiso (data) {
      return _PermisosService.editar(data).then(({ data }) => {
        return Promise.resolve(data)
      })
    }
  }
})
