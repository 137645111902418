import { defineStore } from 'pinia'
import Services from '../../../services/services.service'
const _Services = new Services()

export const useServiciosStore = defineStore('serviciosStore', {
  state: () => ({
    servicios_flitrados: [],
    todos_servicios: []
  }),
  getters: {
    _servicios_flitrados: state => state.servicios_flitrados,
    _todos_servicios: state => state.todos_servicios
  },
  actions: {
    buscarServicios (name) {
      _Services.search(name).then(({ data }) => {
        this.servicios_flitrados = data
        return Promise.resolve(data)
      })
    },
    obtenerTodosServicios () {
      return _Services.todos().then(({ data }) => {
        this.todos_servicios = data
        return data
      })
    }
  }
})
