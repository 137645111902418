<template>
  <section>
    <div class="w-full">
      <Toast />
      <DataTable
        :value="listadoPermisos.rows"
        tableStyle="min-width: 50rem"
        class="mt-4"
        dataKey="id"
        responsiveLayout="scroll"
      >
        <Column field="id" class="text-sm" header="ID"></Column>
        <Column field="name" class="text-sm" header="Nombre" filterField="name"></Column>
        <Column field="description" class="text-sm" header="Descripción del permiso"></Column>
        <Column field="action" class="text-sm" header="Action"></Column>
        <Column field="nombre_permiso_padre" class="text-sm" header="Permiso padre"></Column>
        <Column field="nombre_servicio_padre" class="text-sm" header="Servicio padre"></Column>
        <Column field="edicion" class="text-sm" header="Creación/Edición">
          <template #body="slotProps">
            <div class="text-center p-1" :class="slotProps.data.edicion ? 'text-green-600 font-bold bg-green-200 rounded-md' : 'text-red-600 font-bold bg-red-200 rounded-md'">
              <p>{{ slotProps.data.edicion ? 'Editable' : 'No editable' }}</p>
            </div>
          </template>
        </Column>
        <Column field="edicion" class="text-sm text-center" header="Acciones">
          <template #body="slotProps">
            <div class="w-full flex justify-center">
              <Button @click="abrirModalEditarPermiso(slotProps.data)" icon="pi pi-pencil" text rounded></Button>
            </div>
          </template>
        </Column>
      </DataTable>
      <Paginator v-model:first="params.offset"
        :rows="params.limit"
        :totalRecords="listadoPermisos.count"
        :rowsPerPageOptions="[2,10,20,30,100]"
        @page="onPage($event)"
      />
    </div>
    <Dialog header="Editar permiso" :closable="false" v-model:visible="displayModalEditarPermiso" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '30vw'}" :modal="true">
      <template #header>
        <div class="w-full flex items-center">
        <div class="w-3/12 font-bold text-xl">
          <p>Editar permiso</p>
        </div>
        <div class="w-9/12">
          <div v-if="dataPermisoEditar.creo" class="w-full justify-end flex italic text-gray-600 text-xs">
            <p>Creado por: {{ dataPermisoEditar.creo.persona.full_name }} el dia {{ dayjs(dataPermisoEditar.createdAt).format('DD-MM-YYYY h:mm A') }}</p>
          </div>
          <div v-if="dataPermisoEditar.actualizo" class="w-full justify-end flex italic text-gray-600 text-xs">
            <p>Actualizado por: {{ dataPermisoEditar.actualizo.persona.full_name }} el dia {{ dayjs(dataPermisoEditar.updatedAt).format('DD-MM-YYYY h:mm A') }}</p>
          </div>
        </div>
      </div>
      </template>
      <div class="w-full my-2">
        <div class="w-full grid grid-cols-2 gap-2">
          <div v-if="!dataPermisoEditar.permisosHijos.length || dataPermisoEditar.permisosHijos.some(a => a.creacion_edicion)">
            <label class="w-full text-sm text-gray-600" for="">Editable</label>
            <div class="w-full">
              <InputSwitch v-model="dataPermisoEditar.editable" />
            </div>
          </div>
          <div v-if="dataPermisoEditar.servicio">
            <label class="w-full text-sm text-gray-600" for="">Servicio</label>
            <div class="w-full bg-green-600 p-2 rounded-md">
              {{ dataPermisoEditar.servicio }}
            </div>
          </div>
        </div>
        <div class="field w-full" v-if="!dataPermisoEditar.servicio">
          <label class="w-full text-sm text-gray-600" for="name">Permiso padre</label>
          <AutoComplete
            v-model="permisoPadreSeleccionado"
            modelValue="id"
            :pt="{ root: { style: 'height: 2.3rem' } }"
            size="small"
            @complete="buscarPermisos(permisoPadreSeleccionado)"
            @item-select="setPermisoPadreSeleccionado"
            :suggestions="permisosFiltrados"
            optionLabel="action"
            class="text-xs w-full"
            inputClass="w-full"
            :forceSelection="true"
          >
            <template #option="slotProps">
              <div class="flex align-options-center">
                <div class="text-sm pr-1">
                  <div class="flex flex-wrap gap-2"><p style="text-wrap: balance" class="">Nombre: {{ slotProps.option.name }}</p></div>
                  <div class="flex flex-wrap gap-2"><p style="text-wrap: balance">Action: {{ slotProps.option.action }}</p></div>
                </div>
              </div>
            </template>
          </AutoComplete>
        </div>
        <div class="field w-full" v-else>
          <label class="w-full text-sm text-gray-600" for="name">Servicio padre</label>
          <AutoComplete
            v-model="dataPermisoEditar.servicioPadre"
            modelValue="id"
            dataKey="id"
            value="id"
            :pt="{ root: { style: 'height: 2.3rem' } }"
            size="small"
            @complete="buscarServicios(dataPermisoEditar.service_id)"
            :suggestions="listadoServicios"
            optionLabel="name"
            class="text-xs w-full"
            inputClass="w-full"
            :forceSelection="true"
          ></AutoComplete>
        </div>
      </div>
      <div class="w-full my-2">
        <div class="field w-full">
          <label class="w-full text-sm text-gray-600" for="name">Nombre del permiso</label>
          <InputText class="w-full" id="name" v-model="dataPermisoEditar.name" />
        </div>
      </div>
      <div class="w-full my-2">
        <div class="field w-full">
          <label class="w-full text-sm text-gray-600" for="description">Descripción</label>
          <InputText class="w-full" id="description" v-model="dataPermisoEditar.description" />
        </div>
      </div>
      <div class="w-full my-2">
        <div class="field w-full">
          <label class="w-full text-sm text-gray-600" for="name">Nombre del action</label>
          <InputText class="w-full" id="name" v-model="dataPermisoEditar.action" />
        </div>
      </div>
      <template #footer>
          <Button label="Cancelar" icon="pi pi-times" @click="cerrarModalEditarPermiso()" class="p-button-text"/>
          <Button label="Guardar" icon="pi pi-check" @click="editarPermiso()" autofocus />
      </template>
    </Dialog>
  </section>
</template>

<script>
import { onMounted, ref, computed } from 'vue'
import { usePermisosStore } from '../stores/permisos.store'
import { useToast } from 'primevue/usetoast'
import { useServiciosStore } from '../stores/servicios.store'
import Swal from 'sweetalert2'
import dayjs from 'dayjs'
export default {
  name: 'listadoPermisos',
  setup () {
    // Stores
    const permisosStore = usePermisosStore()
    const serviciosStore = useServiciosStore()
    // Referencias
    const limit = ref(10)
    const offset = ref(0)
    const page = ref(0)
    const toast = useToast()
    const dataPermisoEditar = ref({})
    const permisoPadreSeleccionado = ref('')
    const displayModalEditarPermiso = ref(false)
    // Computed
    const listadoPermisos = computed(() => permisosStore._permisos_paginado)
    const params = computed(() => permisosStore._params)
    const listadoServicios = computed(() => serviciosStore._servicios_flitrados)
    const permisosFiltrados = computed(() => permisosStore._permisos_flitrados)
    // Metodos
    const onPage = async ({ first, page, pageCount, rows }) => {
      // limit.value = rows
      permisosStore.$patch((state) => {
        state.params.limit = rows
      })
      permisosStore.paginarPermisos()
    }
    const abrirModalEditarPermiso = (data) => {
      dataPermisoEditar.value = {
        ...data,
        editable: data.edicion
      }
      permisoPadreSeleccionado.value = data.permisoPadre
      // permisoPadreSeleccionado.value = data.
      displayModalEditarPermiso.value = true
    }
    const editarPermiso = () => {
      if (!dataPermisoEditar.value.action) return toast.add({ severity: 'error', summary: 'Error', detail: 'Action no puede quedar vacio', life: 6000 })
      if (!dataPermisoEditar.value.name) return toast.add({ severity: 'error', summary: 'Error', detail: 'Nombre no puede quedar vacio', life: 6000 })
      if (!dataPermisoEditar.value.parent_permission_id && !dataPermisoEditar.value.service_id) return toast.add({ severity: 'error', summary: 'Error', detail: 'Completa todos los datos para continuar', life: 6000 })
      permisosStore.editarPermiso(dataPermisoEditar.value).then((data) => {
        Swal.fire(
          'Guardado',
          'Permiso editado con éxito',
          'success'
        ).then(() => {
          cerrarModalEditarPermiso()
          permisosStore.paginarPermisos()
        })
      })
    }
    const cerrarModalEditarPermiso = () => {
      dataPermisoEditar.value = {}
      displayModalEditarPermiso.value = false
    }
    const buscarPermisos = (search) => {
      if (search.length > 3) permisosStore.buscarPermisos(search)
    }
    const buscarServicios = (name) => {
      serviciosStore.buscarServicios(name)
    }
    const setPermisoPadreSeleccionado = () => {
      dataPermisoEditar.value.parent_permission_id = permisoPadreSeleccionado.value.id
      dataPermisoEditar.value.action = permisoPadreSeleccionado.value.action + '.'
    }
    onMounted(() => {
      permisosStore.paginarPermisos()
    })
    return {
      listadoPermisos,
      limit,
      page,
      offset,
      onPage,
      params,
      abrirModalEditarPermiso,
      displayModalEditarPermiso,
      dataPermisoEditar,
      cerrarModalEditarPermiso,
      editarPermiso,
      buscarPermisos,
      buscarServicios,
      listadoServicios,
      permisosFiltrados,
      dayjs,
      permisoPadreSeleccionado,
      setPermisoPadreSeleccionado
    }
  }
}
</script>
<style>
:global(.swal2-container) {z-index: 1000000 !important}
</style>
